(function (factory) {
    if (typeof define === "function" && define.amd) {
        define(["jquery", "../jquery.validate-1.13.1"], factory);
    } else {
        factory(jQuery);
    }
}(function ($) {

    /*
     * Translated default messages for the jQuery validation plugin.
     * Locale: FR (French; français)
     */
    $.extend($.validator.messages, {
        required: "This field is required.",
        remote: "Please fix this field.",
        email: "Please enter a valid email address. Example: john.doe@mywebmail.com",
        url: "Please enter a valid URL.",
        date: "Please enter a valid date.",
        dateISO: "Please enter a valid date ( ISO ).",
        number: "Please enter a valid number.",
        digits: "Please enter only digits.",
        creditcard: "Please enter a valid credit card number.",
        equalTo: "Please enter the same value again.",
        extension: "Please enter a value with a valid extension.",
        maxlength: $.validator.format("Please enter no more than {0} characters."),
        minlength: $.validator.format("Please enter at least {0} characters."),
        rangelength: $.validator.format("Please enter a value between {0} and {1} characters long."),
        range: $.validator.format("Please enter a value between {0} and {1}."),
        max: $.validator.format("Please enter a value less than or equal to {0}."),
        min: $.validator.format("Please enter a value greater than or equal to {0}."),
        maxWords: $.validator.format("Please enter {0} words or less."),
        minWords: $.validator.format("Please enter at least {0} words."),
        rangeWords: $.validator.format("Please enter between {0} and {1} words."),
        letterswithbasicpunc: "Letters or punctuation only please",
        alphanumeric: "Letters, numbers, and underscores only please",
        lettersonly: "Letters only please",
        nowhitespace: "No white space please",
        ziprange: "Your ZIP-code must be in the range 902xx-xxxx to 905xx-xxxx",
        integer: "A positive or negative non-decimal number please",
        vinUS: "The specified vehicle identification number (VIN) is invalid.",
        dateITA: "Please enter a correct date",
        time: "Please enter a valid time, between 00:00 and 23:59",
        phoneUS: "Please specify a valid phone number",
        phoneUK: "Please specify a valid phone number",
        mobileUK: "Please specify a valid mobile number",
        strippedminlength: $.validator.format("Please enter at least {0} characters"),
        email2: "Please enter a valid email address. Example: john.doe@mywebmail.com",
        url2: "Please enter a valid URL.",
        creditcardtypes: "Please enter a valid credit card number.",
        ipv4: "Please enter a valid IP v4 address.",
        ipv6: "Please enter a valid IP v6 address.",
        require_from_group: $.validator.format("Please fill at least {0} of these fields."),
        nifES: "Please specify a valid NIF number.",
        nieES: "Please specify a valid NIE number.",
        cifES: "Please specify a valid CIF number.",
        postalCodeCA: "Please specify a valid postal code"
    });

}));