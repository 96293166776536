/*
 * Copyright (C) 2015 - 2018 Kosmos contact@kosmos.fr
 *
 * Projet: webapp-legacy
 * Version: 7.1.2
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
(function (jQuery) {

    window.LOCALE_FO = window.LOCALE_FO || {};

    jQuery.extend(true, window.LOCALE_FO, {
        search: {
            search_by_keyword: "Search by keywords",
            no_result: "No results proposal",
            wait_result: "Searching results...",
            query_too_short: "Type in 3 or more characters for results",
            selected_option: "The selected otion is highlighted.",
            assistive_hint: "When autocomplete results are available use up and down arrows to review and enter to select. Touch device users, explore by touch or with swipe gestures."
        }
    });
})(jQuery.noConflict());
