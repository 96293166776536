/*
 * Copyright (C) 2015 - 2018 Kosmos contact@kosmos.fr
 *
 * Projet: frontgen
 * Version: 7.1.0
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
(function (jQuery) {

    window.LOCALE_BO = window.LOCALE_BO || {};

    jQuery.extend(true, window.LOCALE_BO, {
        supprimer: "Delete",
        ajouter: "Add",
        valider: "Validate",
        archiver: "Archive",
        consulter: "Read",
        modifier: "Edit",
        nouvelleFenetre: "(New Window)",
        selectionner: "Select",
        confirmer: "Confirm",
        fermer: "No",
        erreur: "Error",
        ok: "Yes",
        confirmQuitterPage: "Are you sure you want to leave this page&nbsp;?",
        confirmSupprFiche: "The deletion of this content can impact some sections' and contents' URLs. Are you sure you want to delete this content&nbsp;?",
        selectionnerUnELement: "Please select at least one item before starting an action.",
        confirmSupprFiches: "The deletion of these contents can impact some sections' and contents' URLs. Are you sure you want to delete these contents&nbsp;?",
        confirmSupprUtilisateur: "Are you sure you want to delete this user&nbsp;?",
        confirmSupprUtilisateurs: "Are you sure you want to delete these users&nbsp;?",
        confirmSupprRubrique: "Are you sure you want to delete the section &quot;{0}&quot; with its full arborescence and contents ? Careful, only the contents and theirs plugin will be deleted.<br/>This operation can't be undone.",
        confirmArchFiches: "The archiving of these contents can impact some sections' and contents' URLs. Are you sure you want to archive these contents&nbsp;?<br(Only online contents will be archived.)",
        lExtension: "The extension",
        leModule: "The module",
        patienter: "<br/> Please wait ...",
        voirEnLigne: "View online",
        actions: "Actions",
        erreurRecherche: "An error occurred while calculating your search results.",
        erreurActionMasse: "An error occurred while processing your action.",
        confirmationActionMasseSuppr: "The selected contents have been deleted.",
        confirmationActionMasseArchi: "The published contents have been archived.",
        confirmationActionMasseUtilisateur: "The selected users have been deleted.",
        activer: "Activate",
        desactiver: "Disable",
        captchaError: "Invalid validation word.",

        // Etats
        enCoursSuppression: "is being deleted.",
        enCoursRechargement: "Reload in progress",
        enSuccesSuppression: "Delete was successful",
        enErreurSuppression: "An error occurred while deleting",
        enCoursdActivation: "is being activated",
        enSuccesActivation: "Activation was successful",
        enErreurActivation: "An error occurred during activation",
        enCoursdeDesactivation: "is being disabled.",
        enSuccesDesactivation: "Deactivation was successful",
        enErreurDesactivation: "An error occurred while deactivating",
        enSuccesRechargement: "Reload complete",
        enErreurRechargement: "An error occurred while reloading",
        enSucces: "Success",
        enErreur: "Error",

        // Validations
        validationMessages: {
            ksupDate: "Please provide a valid date",
            ksupPhone: "Please provide a valid phone number",
            pwdVerdicts: ["Low", "Normal", "Average", "Strong", "Very strong"],
            pwdErrorMessages: {
                password_too_short: "Too short",
                email_as_password: "Do not use your email as a password",
                same_as_username: "Your password cannot contain your login ID",
                two_character_classes: "Vary character case",
                repeated_character: "Too many repetitions",
                sequence_found: "Your password contains sequences"
            }
        },

        // Services
        services: {
            arbre: {
                indisponible: "Tree manager service not reachable. No action will be performed.",
                confirmerSuppression: "Are you sure you want to delete this item \{0\}?"
            },
            auto_complete: {
                search: "Search in progress...",
                noresult: "No results"
            }
        },

        // Popins
        popin: {
            title: {
                generique: "Select an item",
                structure: {
                    mono: "Select an institution",
                    multi: "Select institutions"
                },
                rubrique: {
                    mono: "Select a section",
                    multi: "Select sections"
                },
                groupe: {
                    mono: "Select a group",
                    multi: "Select groups"
                },
                utilisateur: "Select a user",
                objet: "Select a content",
                pagelibre: "",
                photo: "Select a picture",
                fichier: "Select a file"
            }
        },

        //Médias
        media: {
            player: {
                mute: "Enable / Disable Sound",
                playPause: "Play / Pause",
                fullscreen: "Full Screen",
                tracks: "Subtitles",
                postRoll: "Close"
            }
        },

        // saisie front
        kselect: {
            mono: {
                select: "Select",
                clear: "Clear"
            },
            multi: {
                parentLabel: "Available item(s)",
                compositionLabel: "Selected item(s)"
            }
        },

        libelle: {
            inconnu: "Unknown label"
        },

        // CKEditor
        ckeditor: {
            anchor: {
                alert: "The name must start with a letter and can only contains following characters : A-Z, a-z, 0-9, :-_."
            },
            notifications: {
                spellcheker: "Spell-checker is available through <kbd title=&quot;Controle&quot;>Ctrl</kbd> + right clic"
            },
            plugins: {
                listeFiche: {
                    title: "Contents list",
                    insert: "Insert a list"
                },
                link: {
                    title: "Insert a link",
                    anchor: {
                        tip: {
                            alert: "No anchors was found in the current page."
                        }
                    }
                },
                kflipbook: {
                    title: "Insert a Flipbook",
                    menu: "Flipbook properties"
                },
                kimage: {
                    title: "Insert a picture",
                    menu: "Image properties"
                },
                kvideo: {
                    title: "Insert a video",
                    menu: "Video properties"
                },
                kaudio: {
                    title: "Insert audio",
                    menu: "Audio properties"
                },
                kflash: {
                    title: "Insert a Flash animation",
                    menu: "Flash animation properties"
                },
                kpdfviewer: {
                    title: "",
                    menu: ""
                },
                ktag: {
                    title: "Tags",
                    menu: "Tag properties"
                }
            }
        }
    });

    window.LOCALE_FO = window.LOCALE_FO || {};

    jQuery.extend(true, window.LOCALE_FO, {
        // Popins
        popin: {
            title: {
                dossier: {
                    mono: "Select a folder"
                }
            }
        },
        // Galerie
        galerie: {
            controls: {
                next: "Next (right arrow)",
                previous: "Previous (left arrow)",
                close: "Close (Esc)"
            },
            display: {
                counter: "on",
                loading: "Loading...",
                errorIFrame: "An error has occurred while trying to load the item",
                errorImage: "An error has occurred while trying to load the picture",
                errorFormatInconnu: "This file format is not supported by the media galery"
            }
        },
        // Menu
        menu: {
            retour: "Back"
        },
        search: {
            search_by_keyword: "Search by keywords",
            no_result: "No results proposal",
            wait_result: "Searching results...",
            query_too_short: "Type in 3 or more characters for results",
            selected_option: "The selected otion is highlighted.",
            assistive_hint: "When autocomplete results are available use up and down arrows to review and enter to select. Touch device users, explore by touch or with swipe gestures."
        },
        tooltip: {
            char_remaining: "{0} out of {1} characters allowed"
        }
    });

})(jQuery.noConflict());
