window.RESPONSIVE_PREVISUALIZATION = {
    messages: {
        device_mobile_portrait: "Mobile in portrait mode",
        device_mobile_landscape: "Mobile in landscape mode",
        device_tablet_portrait: "Tablet in portrait mode",
        device_tablet_landscape: "Tablet in landscape mode",
        device_desktop: "Desktop",
        resolution: "Preview of the site in resolution",
        disclaimer: "Click on a type of terminal to get a preview of the site.<br/><br/>The actual rendering may vary depending on the specificities of the mobile as well as the browser used.",
        close: "Close"
    }
};